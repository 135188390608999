var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showViewAsCustomer && _vm.showNonContractedTenants
        ? _c("v-autocomplete", {
            attrs: {
              dense: "",
              "hide-details": "",
              items: _vm.computedCustomers,
              "search-input": _vm.search,
              loading: _vm.loading,
              "no-filter": _vm.noFilter,
              clearable: "",
              outlined: "",
              label: "View as Customer",
              color: "primary"
            },
            on: {
              "update:searchInput": function($event) {
                _vm.search = $event
              },
              "update:search-input": function($event) {
                _vm.search = $event
              },
              change: function($event) {
                _vm.search = null
              },
              "click:clear": _vm.clearViewAsCustomer
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item",
                  fn: function(ref) {
                    var parent = ref.parent
                    var item = ref.item
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      item.text || item.nowwId
                        ? _c(
                            "v-list-item-content",
                            { staticClass: "py-2" },
                            [
                              _c("v-list-item-title", {
                                domProps: { textContent: _vm._s(item.text) }
                              }),
                              _c("v-list-item-subtitle", {
                                domProps: { textContent: _vm._s(item.nowwId) }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                }
              ],
              null,
              false,
              1225242470
            ),
            model: {
              value: _vm.viewAs,
              callback: function($$v) {
                _vm.viewAs = $$v
              },
              expression: "viewAs"
            }
          })
        : _vm.showViewAsCustomer && !_vm.showNonContractedTenants
        ? _c("v-select", {
            attrs: {
              dense: "",
              "hide-details": "",
              items: _vm.computedCustomers,
              loading: _vm.loading,
              "no-filter": _vm.noFilter,
              outlined: "",
              label: "View as Customer",
              color: "primary"
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(ref) {
                  var parent = ref.parent
                  var item = ref.item
                  var on = ref.on
                  var attrs = ref.attrs
                  return [
                    item.text || item.nowwId
                      ? _c(
                          "v-list-item-content",
                          { staticClass: "py-2" },
                          [
                            _c("v-list-item-title", {
                              domProps: { textContent: _vm._s(item.text) }
                            }),
                            _c("v-list-item-subtitle", {
                              domProps: { textContent: _vm._s(item.nowwId) }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ]),
            model: {
              value: _vm.viewAs,
              callback: function($$v) {
                _vm.viewAs = $$v
              },
              expression: "viewAs"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <v-autocomplete
      v-if="showViewAsCustomer && showNonContractedTenants"
      v-model="viewAs"
      dense
      hide-details
      :items="computedCustomers"
      :search-input.sync="search"
      :loading="loading"
      :no-filter="noFilter"
      clearable
      outlined
      label="View as Customer"
      color="primary"
      @change="search = null"
      @click:clear="clearViewAsCustomer"
    >
      <template v-slot:item="{ parent, item, on, attrs }">
        <v-list-item-content
          v-if="item.text || item.nowwId"
          class="py-2"
        >
          <v-list-item-title v-text="item.text" />
          <v-list-item-subtitle v-text="item.nowwId" />
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-select
      v-else-if="showViewAsCustomer && !showNonContractedTenants"
      v-model="viewAs"
      dense
      hide-details
      :items="computedCustomers"
      :loading="loading"
      :no-filter="noFilter"
      outlined
      label="View as Customer"
      color="primary"
    >
      <template v-slot:item="{ parent, item, on, attrs }">
        <v-list-item-content
          v-if="item.text || item.nowwId"
          class="py-2"
        >
          <v-list-item-title v-text="item.text" />
          <v-list-item-subtitle v-text="item.nowwId" />
        </v-list-item-content>
      </template>
    </v-select>
  </div>
</template>

<script>
import { tenantService } from '@/shared/services'
import claims from '@/shared/models/auth/claims'
import { debounce } from 'lodash'

export default {
  props: {
    showNonContractedTenants: {
      type: Boolean,
      default: true
    }
  },

  data: function () {
    return {
      contractedTenants: [],
      otherTenants: [],
      search: null,
      loadingCount: 0,
      loadingSearch: false,
      noFilter: true
    }
  },

  computed: {
    loading () {
      return this.loadingCount > 0 || this.loadingSearch
    },
    showViewAsCustomer () {
      return this.$store.getters['auth/hasClaimKV'](claims.MMS_TEBT)
    },
    computedCustomers () {
      if (this.showNonContractedTenants) {
        return [
          { text: 'All Customers', value: -1 },
          { header: 'Contracted Customers' },
          ...this.filteredContractedTenants,
          { divider: true },
          { header: 'Other Customers... (Search to find)' },
          ...this.filteredOtherTenants
        ]
      } else {
        return [
          { text: 'All Customers', value: -1 },
          { header: 'Contracted Customers' },
          ...this.filteredContractedTenants
        ]
      }
    },
    filteredContractedTenants () {
      return this.contractedTenants.filter(this.filterFunc)
    },
    filteredOtherTenants () {
      return this.otherTenants.filter(this.filterFunc)
    },
    allTenants () {
      return [...this.contractedTenants, ...this.otherTenants]
    },
    viewAs: {
      get () {
        return this.$store.getters.viewAsCustomer()
      },
      set (value) {
        let selected = this.allTenants.find(x => x.value === value)
        let nowwId = null
        let isContracted = false
        if (selected) {
          nowwId = selected.nowwId
          isContracted = selected.isContracted
        }
        this.$store.dispatch('setViewAsCustomer', { crmId: value, nowwId, isContracted })
      }
    }
  },

  created () {
    this.loadContractedCustomers()
    this.loadOtherCustomer(this.$store.getters['viewAsCustomer']())
  },

  methods: {
    loadContractedCustomers () {
      this.loadingCount++
      tenantService
        .getTenants()
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
            this.contractedTenants = []
          } else {
            this.contractedTenants = resp.map(function (val, index) {
              return {
                text: val.name,
                value: val.crmId,
                nowwId: val.nowwId,
                isContracted: true
              }
            })
          }
        })
        .finally(() => {
          this.loadingCount--
        })
    },
    loadOtherCustomer (customerCrmId) {
      if (
        !this.showNonContractedTenants ||
        this.$store.getters['isViewAllCustomersVal'](customerCrmId)
      ) { return }
      this.loadingCount++
      tenantService
        .getTenantByCrmId(customerCrmId)
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
            this.otherTenants = []
          } else {
            this.otherTenants = [
              {
                text: resp.name,
                value: resp.crmId,
                nowwId: resp.nowwId,
                isContracted: false
              }
            ]
          }
        })
        .finally(() => {
          this.loadingCount--
        })
    },
    loadOtherCustomers () {
      if (!this.search || !this.showNonContractedTenants) {
        this.loadingSearch = false
        return
      }
      this.loadingCount++
      tenantService
        .getOtherTenants(this.search)
        .then(resp => {
          if (resp.errorResponse) {
            this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
            this.otherTenants = []
          } else {
            this.otherTenants = resp.map(function (val, index) {
              return {
                text: val.name,
                value: val.crmId,
                nowwId: val.nowwId,
                isContracted: false
              }
            })
          }
        })
        .finally(() => {
          this.loadingCount--
          this.loadingSearch = false
        })
    },
    clearViewAsCustomer (event) {
      // console.log(event);
    },
    debouncedSearch: debounce(function () {
      this.loadOtherCustomers()
    }, 700),
    filterFunc (x) {
      return (
        !this.search ||
        x.text.toLowerCase().includes(this.search.toLowerCase()) ||
        x.nowwId.toString().includes(this.search)
      )
    }
  },

  watch: {
    search () {
      if (this.showNonContractedTenants) this.loadingSearch = true
      this.debouncedSearch()
    }
  }
}
</script>
